body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #222425;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
}

.registration-form-container {
  width: 400px;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 10px;
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration-form-container h2 {
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.registration-form-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration-form-container label {
  margin-bottom: 10px;
  color: #fff;
  width: 100%;
  text-align: left;
}

.registration-form-container input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #555;
  border-radius: 10px;
  background-color: #444;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  border: none;
  text-align: center;
}

button:hover {
  background-color: #495057;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}
