.q-detail-container {
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    color:#fff;
}

.q-detail-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
}

.q-detail-content {
    border-radius: 5px;
    padding: 20px;
    color: #fff;

}



.q-statement {
    font-size: 16px;
    line-height: 1.6;
}

.loading {
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
}
.code-input {
    width: 100%;
    height: 200px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.language-select {
    margin-top: 20px;
    color: #fff;
}

.language-select label {
    margin-right: 10px;
}

.language-select select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}