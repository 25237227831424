.submit-form-container {
  margin: 20px auto;
  width: 80%;
  max-width: 800px;
  color: #fff;
  text-align: center;
}

.submit-form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
}

.submit-form-content {
  border-radius: 5px;
  padding: 20px;
  background-color: #282c34; /* Assuming a dark background */
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Extend background width a bit more */
  margin: 0 auto; /* Center the content */
}

.submit-form-content label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.submit-form-content input,
.submit-form-content textarea,
.submit-form-content select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #333; /* Dark input background */
  color: #fff; /* Light text color */
}

.submit-form-content textarea[name="statement"] {
  max-height: 200px; /* Fixed height */
  overflow-y: scroll; /* Make it scrollable */
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.language-select {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  color: #fff;
}

.language-select label {
  margin-right: 10px;
}

.language-select select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #333; /* Dark select background */
  color: #fff; /* Light text color */
}
